import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    component: () => import('@/containers/Public'),
    children: [
      {
        path: '', name: 'login', component: () => import('@/views/public/Login')
      }
    ]
  },
  {
    path: '/Secure',
    component: () => import('@/containers/Secure'),
    children: [
      { path: 'Home', name: 'home', component: () => import('@/views/secure/Home') },
      { path: 'AdminHome', name: 'admin-home', component: () => import('@/views/secure/admin/Home') },
      { path: 'AdminFormTemplates', name: 'admin-form-templates', component: () => import('@/views/secure/admin/AdminFormTemplates') },
      { path: 'AdminUsers', name: 'admin-users', component: () => import('@/views/secure/admin/AdminUsers') },
      { path: 'PicosCriteria', name: 'data-entry-options', component: () => import('@/views/secure/configuration/PicosCriteria') },
      { path: 'ImportHome', name: 'import-home', component: () => import('@/views/secure/import/Home') },
      { path: 'ImportFile', name: 'import-file', component: () => import('@/views/secure/import/ImportFile') },
      { path: 'ImportProject', name: 'import-project', component: () => import('@/views/secure/import/ImportProject') },
      { path: 'ImportManual', name: 'import-manual', component: () => import('@/views/secure/import/ImportManual') },
      { path: 'ViewAll', name: 'configuration-view-all', component: () => import('@/views/secure/configuration/ViewAll') },
      { path: 'GeneralInfo', name: 'configuration-general-info', component: () => import('@/views/secure/configuration/GeneralInfo') },
      { path: 'PicosCriteria', name: 'configuration-data-entry-options', component: () => import('@/views/secure/configuration/PicosCriteria') },
      { path: 'Stages', name: 'configuration-stages', component: () => import('@/views/secure/configuration/Stages') },
      { path: 'Studies', name: 'configuration-studies', component: () => import('@/views/secure/configuration/Studies') },
      { path: 'Forms', name: 'configuration-forms', component: () => import('@/views/secure/configuration/Forms') },
      { path: 'Users', name: 'configuration-users', component: () => import('@/views/secure/configuration/Users') },
      { path: 'ExclusionReasons', name: 'configuration-exclusion-reasons', component: () => import('@/views/secure/configuration/ExclusionReasons') },
      { path: 'KeywordsTags', name: 'configuration-keywords-tags', component: () => import('@/views/secure/configuration/KeywordsTags') },
      { path: 'Preferences', name: 'configuration-preferences', component: () => import('@/views/secure/configuration/Preferences') },
      { path: 'ManageHome', name: 'manage-home', component: () => import('@/views/secure/manage/Home') },
      { path: 'Select', name: 'select', component: () => import('@/views/secure/manage/Select') },
      { path: 'IndividualReview', name: 'individual-review', component: () => import('@/views/secure/manage/IndividualReview') },
      { path: 'StudyMappingIndividualReviewByStudies', name: 'study-mapping-individual-review-by-studies', component: () => import('@/views/secure/manage/StudyMappingIndividualReviewByStudies') },
      { path: 'StudyMappingIndividualReviewByCitations', name: 'study-mapping-individual-review-by-citations', component: () => import('@/views/secure/manage/StudyMappingIndividualReviewByCitations') },
      { path: 'ReviewDE', name: 'manage-review-de', component: () => import('@/views/secure/manage/ReviewDE') },
      { path: 'FormEditor', name: 'manage-form-editor', component: () => import('@/views/secure/manage/FormEditor') },
      { path: 'ReconciliationHome', name: 'reconciliation-home', component: () => import('@/views/secure/reconciliation/Home') },
      { path: 'BatchReview', name: 'reconciliation-batch-review', component: () => import('@/views/secure/manage/BatchReview') },
      { path: 'StageDiscrepancies', name: 'reconciliation-stage-discrepancies', component: () => import('@/views/secure/reconciliation/StageDiscrepancies') },
      { path: 'DataDiscrepancies', name: 'reconciliation-data-discrepancies', component: () => import('@/views/secure/reconciliation/DataDiscrepancies') },
      { path: 'ValidationHome', name: 'validation-home', component: () => import('@/views/secure/validation/Home') },
      { path: 'StageValidation', name: 'validation-stage-validation', component: () => import('@/views/secure/validation/StageValidation') },
      { path: 'Reports', name: 'reports', component: () => import('@/views/secure/reports/Reports') },
      { path: 'StageHistoryByCitation', name: 'reports-stage-history-by-citation', component: () => import('@/views/secure/reports/StageHistoryByCitation') },
      { path: 'CitationsWithAnnotationsDiscrepancies', name: 'reports-citations-with-annotations-discrepancy', component: () => import('@/views/secure/reports/CitationsWithAnnotationsDiscrepancies') },
      { path: 'MetricsReport', name: 'reports-metrics-report', component: () => import('@/views/secure/reports/MetricsReport') },
      { path: 'PotentialDuplicates', name: 'reports-potential-duplicates', component: () => import('@/views/secure/reports/PotentialDuplicates') },
      { path: 'StudyMapping', name: 'manage-study-mapping-study-mapping', component: () => import('@/views/secure/manage/StudyMapping') },
      { path: 'DataExtraction', name: 'manage-data-extraction', component: () => import('@/views/secure/manage/DataExtraction') },
      { path: 'DataExtractionReport', name: 'reports-data-extraction', component: () => import('@/views/secure/reports/DataExtractionReport') }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
