<template>
  <div>
    <app-header />
    <router-view />&nbsp;
    <app-footer />
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader'
import AppFooter from '@/components/AppFooter'

export default {
  name: 'core',
  components: {
    AppHeader,
    AppFooter
  }
}
</script>

<style lang="scss">
@import './scss/style';
</style>
