<template>
  <div class="hdr-navigation-actions" v-if="this.$store.state.showActions">
    <div :class="classObject(item.name, item.enabled)" v-for="item in this.$store.state.navigation.find(navigation => navigation.name === this.$store.state.selectedNavigation).actions" :key="item.name"  v-on:click="routeAction(item.path, item.name, item.enabled)">{{ item.title }}</div>
  </div>
</template>

<script lang="js">
export default {
  name: 'actions',
  methods: {
    routeAction: function (selectedActionPath, selectedAction, enabled) {
      if (enabled) {
        // alert(this.$store.state.selectedNavigation)
        this.$router.push({ path: selectedActionPath })
        this.$store.state.selectedAction = selectedAction
        if (this.$store.state.selectedNavigation === 'configuration') {
          // alert(this.$store.state.selectedAction)
          if (this.$store.state.selectedAction === 'view-all') {
            this.$store.state.configurationViewAll = true
            this.$store.state.navigation[1].actions[0].visible = true
            this.$store.state.navigation[1].actions[1].visible = true
            this.$store.state.navigation[1].actions[2].visible = true
            this.$store.state.navigation[1].actions[3].visible = true
            this.$store.state.navigation[1].actions[4].visible = true
            this.$store.state.navigation[1].actions[5].visible = true
            this.$store.state.navigation[1].actions[6].visible = true
            this.$store.state.navigation[1].actions[7].visible = true
          } else {
            this.$store.state.configurationViewAll = false
            this.$store.state.navigation[1].actions[0].visible = selectedAction === 'basic-info'
            this.$store.state.navigation[1].actions[1].visible = selectedAction === 'data-entry-options'
            this.$store.state.navigation[1].actions[2].visible = selectedAction === 'stages'
            this.$store.state.navigation[1].actions[3].visible = selectedAction === 'studies'
            this.$store.state.navigation[1].actions[4].visible = selectedAction === 'forms'
            this.$store.state.navigation[1].actions[5].visible = selectedAction === 'users'
            this.$store.state.navigation[1].actions[6].visible = selectedAction === 'exclusion-reasons'
            this.$store.state.navigation[1].actions[7].visible = selectedAction === 'keywords-tags'
          }
        }
      }
    },
    classObject: function (selectedAction, enabled) {
      return {
        'hdr-navigation-item': this.$store.state.selectedAction !== selectedAction & enabled,
        'hdr-navigation-item-active': this.$store.state.selectedAction === selectedAction & enabled,
        'hdr-navigation-item-disabled': !enabled
      }
    }
  }
}
</script>
