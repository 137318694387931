<template>
  <div class="hdr-navigation">hhh
    <div :class="classObject(item.name, item.enabled)" v-for="item in this.$store.state.navigation" :key="item.name" v-on:click="selectNavigation(item.name, item.path, item.enabled)">{{ item.title }}</div>
  </div>
</template>

<script>
export default {
  name: 'navigation',
  methods: {
    selectNavigation: function (selectedNavigation, navigationPath, enabled) {
      if (enabled) {
        this.$store.state.showActions = true
        this.$store.state.selectedNavigation = selectedNavigation
        this.$router.push({ path: navigationPath })
      }
    },
    classObject: function (selectedNavigation, enabled) {
      return {
        'hdr-navigation-item': this.$store.state.selectedNavigation !== selectedNavigation & enabled,
        'hdr-navigation-item-active': this.$store.state.selectedNavigation === selectedNavigation & enabled,
        'hdr-navigation-item-disabled': !enabled
      }
    }
  }
}
</script>
