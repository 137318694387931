import Vue from 'vue'
import Core from '@/Core'
import router from '@/router'
import store from '@/store'

// Import Bootstrap Package
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Import Survey Creator Styles
import 'survey-core/defaultV2.min.css'
import 'survey-creator-core/survey-creator-core.min.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faExternalLinkAlt, faSearchPlus, faHome, faFlag, faInfoCircle, faArrowsAltH } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// Import Kendo Assets
import kendo from '@progress/kendo-ui'
import '@progress/kendo-theme-default/dist/all.css'
import '@progress/kendo-ui/js/kendo.splitter.js'

// Other NPM packages
import VueScrollto from 'vue-scrollto'

// Kendo Installer References
import { ButtonsInstaller } from '@progress/kendo-buttons-vue-wrapper'
import { InputsInstaller } from '@progress/kendo-inputs-vue-wrapper'
// import { DateInputsInstaller } from '@progress/kendo-dateinputs-vue-wrapper'
import { EditorInstaller } from '@progress/kendo-editor-vue-wrapper'
import { LayoutInstaller } from '@progress/kendo-layout-vue-wrapper'
import { GridInstaller } from '@progress/kendo-grid-vue-wrapper'
import { DialogInstaller } from '@progress/kendo-dialog-vue-wrapper'
import { WindowInstaller } from '@progress/kendo-window-vue-wrapper'
import { DropdownsInstaller } from '@progress/kendo-dropdowns-vue-wrapper'
import { DropDownTreeInstaller } from '@progress/kendo-dropdowntree-vue-wrapper'
import { ListBoxInstaller } from '@progress/kendo-listbox-vue-wrapper'
import { ListViewInstaller } from '@progress/kendo-listview-vue-wrapper'
import { TreeListInstaller } from '@progress/kendo-treelist-vue-wrapper'
import { DataSourceInstaller } from '@progress/kendo-datasource-vue-wrapper'
import { UploadInstaller } from '@progress/kendo-upload-vue-wrapper'
import { ColorPicker } from '@progress/kendo-vue-inputs'

Vue.use(kendo)
Vue.use(ButtonsInstaller)
Vue.use(InputsInstaller)
Vue.use(LayoutInstaller)
Vue.use(GridInstaller)
Vue.use(DialogInstaller)
Vue.use(WindowInstaller)
Vue.use(DropdownsInstaller)
Vue.use(DropDownTreeInstaller)
Vue.use(ListBoxInstaller)
Vue.use(ListViewInstaller)
Vue.use(TreeListInstaller)
Vue.use(DataSourceInstaller)
Vue.use(UploadInstaller)
Vue.use(EditorInstaller)
Vue.use(ColorPicker)
Vue.use(VueScrollto)
Vue.use(BootstrapVue)

Vue.prototype.$http = require('axios')

library.add(faExternalLinkAlt, faSearchPlus, faHome, faFlag, faInfoCircle, faArrowsAltH)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  components: {
    Core
  },
  render: h => h(Core)
}).$mount('#app')
