<template>
  <Footer class="ftr">
    <div class="copyright">
      <h6>© 2018 Precision Medicine Group, Inc. All rights reserved</h6>
      <div ref="buttona" id="buttona">{{ this.$store.state.logUser }}</div>
    </div>
    <h6 v-if="this.$store.state.isLogged">
      PICOS CRITERIA
    </h6>
  </Footer>
</template>

<script>
export default {
  name: 'app-footer'
}
</script>
